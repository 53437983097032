import React from 'react';
import { Link, graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
    IntroQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const Institutionen = ({ data }) => (
    <InnerPage title="Die Institutionen" description="">
        <IntroHolder
            title="Die Institutionen"
            subtitle="Institutionen als Wegbereiter der gesellschaftlichen Entwicklung"
            picture={data.introImage}
            breadcrumbs={{
                grandparent: 'Woran Bahá’í glauben',
                parent: 'Der Einzelne und die Gesellschaft',
                'current-item': 'Die Institutionen',
            }}
        >
            <IntroQuote href="/woran-bahai-glauben/der-einzelne-und-die-gesellschaft/die-gemeinschaft/zitate">
                Das Gefüge Seiner neuen Weltordnung, die sich jetzt im Schoße
                der Verwaltungseinrichtungen, die Er selbst geschaffen hat,
                regt, wird als Muster und als Kern jenes Weltstaatenbundes
                dienen, der das sichere, unumgängliche Geschick der Völker und
                Nationen der Erde ist.
            </IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    Institutionen ermöglichen einer Gesellschaft, ihr Potenzial
                    über den reinen Zusammenschluss von Individuen hinaus
                    auszuschöpfen, indem sie kollektiven Bemühungen eine
                    Struktur geben. Sie unterstützen dabei, eine gemeinsame
                    Vision aufzubauen und aufrechtzuerhalten, Ressourcen gerecht
                    zu verteilen und im Allgemeinen die Angelegenheiten von
                    Gruppen und Gesellschaften zu verwalten. Parlamente,
                    Gerichte, Bildungseinrichtungen und zivilgesellschaftliche
                    Organisationen sind einige von vielen Beispielen für
                    Institutionen, die in einer Gesellschaft wirken.
                </p>
                <div>
                    <p>
                        Eine fruchtbare Wechselwirkung und Zusammenarbeit
                        zwischen den Institutionen, der Gesellschaft und dem
                        Einzelnen sind ein notwendiger Bestandteil zum Aufbau
                        einer fortschreitenden Kultur. Dabei können die
                        Institutionen ein Umfeld schaffen, in dem die
                        vielfältigen Perspektiven, Auffassungen und Ansichten
                        zum Erreichen dieses Ziels Raum bekommen. Wie im
                        menschlichen Körper laufen bestimmte Funktionen in einem
                        Organ zusammen und werden dort gesteuert. Genauso dient
                        jedes Organ der Gesundheit des ganzen Körpers, und wenn
                        es einem Körperteil nicht gut geht, leidet der ganze
                        Mensch.
                    </p>
                </div>
                <div>
                    <p>
                        Wie können es Institutionen schaffen, gerechte
                        Entscheidungen zu treffen? Jegliche Entscheidungen
                        sollten im Geiste der Beratung getroffen werden. Dies
                        bedeutet, dass unterschiedliche Meinungen und
                        Perspektiven zu einer zu lösenden Herausforderung gehört
                        werden. Gleichzeitig versucht sich jeder von seinem
                        Gesprächsbeitrag zu lösen und sieht diesen vielmehr als
                        ein Puzzleteil, das man in die Mitte des Tisches gibt
                        und mit dem die ganze Gruppe nun weiterarbeitet, um das
                        Bild zu vervollständigen. Daher ist es ganz natürlich,
                        dass man versucht, Gedanken aufeinander aufzubauen und
                        lösungsorientiert miteinander zu sprechen. Wichtig ist,
                        dass Beratung dem Wohlergehen aller und der Suche nach
                        Wahrheit dient – die wir natürlich niemals vollkommen
                        erkennen können – und nicht der egoistischen Förderung
                        einzelner Interessen. Nur wenn man sich bemüht, die
                        Realität so zu sehen wie sie ist, schafft es eine
                        Grundlage, auf der man eine angemessene Entscheidung
                        treffen kann.
                    </p>
                </div>
                <div>
                    <p>
                        Beratung spielt eine zentrale Rolle in der Beziehung des
                        Individuums mit der Gemeinschaft und mit den
                        Institutionen. Sie ermöglicht es jedem Einzelnen, sich
                        in die Geschicke und Entscheidungsprozesse einzubringen
                        und diese mitzugestalten.
                    </p>
                </div>
                <div>
                    <p>
                        <img
                            alt=""
                            src={data.collage.childImageSharp.fluid.src}
                            className="aligncenter"
                        />
                    </p>
                </div>
                <div>
                    <p>
                        Die Rolle der Institutionen ist es unter anderem, solche
                        Beratungsräume zu schaffen, um im Rahmen ihrer
                        jeweiligen Verantwortungs- und Handlungsbereiche
                        informierte Entscheidungen treffen zu können. Auch in
                        der Bahá’í-Gemeinde gibt es unterschiedliche
                        Institutionen mit definierten Handlungsbereichen.
                    </p>
                </div>
                <div>
                    <p>
                        Dabei sind Bahá’í-Institutionen keine bloßen
                        Verwaltungsinstanzen, die sich ausschließlich mit den
                        internen Aspekten des Gemeindelebens beschäftigen. Sie
                        dienen dem Wohl der ganzen Gesellschaft als Kanäle,
                        durch die der Geist des Glaubens fließt. Dadurch wirken
                        die Beziehungen zwischen den Einzelnen, der Gemeinde und
                        den Institutionen einheitsfördernd und es können
                        lebenssprühende Gemeinschaften entstehen. Im Laufe ihrer
                        zunehmenden Reifung lernen die Bahá’í-Institutionen, die
                        Lebensverhältnisse von immer mehr Menschen zu verstehen
                        und zu verbessern.{' '}
                    </p>
                </div>
                <div>
                    <p>
                        Die administrative Ordnung der Bahá’í-Religion gründet
                        auf den Schriften Bahá’u’lláhs. In ihrem Zentrum steht
                        die höchste internationale Körperschaft, das Universale
                        Haus der Gerechtigkeit. Unter seiner Führung kümmern
                        sich die Nationalen Geistigen Räte und die Lokalen
                        Geistigen Räte um die Angelegenheiten ihrer jeweiligen
                        Gemeinde. Das Universale Haus der Gerechtigkeit und die
                        Geistigen Räte werden demokratisch und insbesondere
                        geheim gewählt. Dabei gibt es keinerlei Art von
                        Wahlwerbung oder Kandidatur. Vielmehr sind alle
                        Mitglieder der Gemeinde über 21 Jahren für einen
                        Geistigen Rat sowohl wahlberechtigt als auch wählbar.{' '}
                    </p>
                </div>
                <div>
                    <p>
                        Neben diesen gewählten Gremien gibt es auch
                        Institutionen, deren Mitglieder ernannt werden. Diese
                        sich ergänzenden Zweige der administrativen Ordnung
                        streben danach, gemeinsam jeden Einzelnen und die
                        Gemeinden zu unterstützen, indem sie beratend,
                        ermutigend und fördernd zur Seite stehen. Die enge und
                        harmonische Zusammenarbeit der unterschiedlichen
                        Institutionen stellt sicher, dass die Mitglieder der
                        Bahá’í-Gemeinde auf der ganzen Welt einen steten Strom
                        von Führung, Liebe und Ermutigung erfahren. Gemeinsam
                        stärken sie die individuellen und kollektiven Bemühungen
                        zum Wohlergehen der gesamten Menschheit.{' '}
                    </p>
                </div>
                <Reference>
                    <p>1. Shoghi Effendi</p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default Institutionen;

export const pageQuery = graphql`
    query {
        introImage: file(relativePath: { eq: "1493373-master.jpg" }) {
            ...fluidImage
        }
        collage: file(relativePath: { eq: "Institution.m.m.2.png" }) {
            ...fluidImage
        }
    }
`;
